import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useForm } from 'react-hook-form';
import useValidation from '../useHooks/useValidation';
import service from '../services/service';
import Input from '../components/Input';
import NewsLetterModal from '../components/NewsLetterModal';
import { Link } from 'react-router-dom';

interface IBlogArticle1Props {}

const BlogArticle9 = ({}: IBlogArticle1Props) => {
  const { handleSubmit, register, errors, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const validation = useValidation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setIsSuccess(false);
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    service
      .subscribe(data.email)
      .then((res) => {
        if (res.success) {
          reset();
          setIsSuccess(true);
          setIsOpen(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="page-bg">
      <NewsLetterModal
        isOpen={isOpen}
        onClose={handleClose}
        defaultIsSuccess={isSuccess}
      />
      <div className="page page-about">
        <Header />
        <main className="blog-main">
          <div className="container blog-big_top">
            <p className="back-link">
              <Link to={'/blog'}>
                <span>Retour au blog</span>
              </Link>
            </p>

            <article className="blog-big">
              <div className="blog-columns2 blog-big_first">
                <div className="blog-columns2_left">
                  <h1>
                    Newsletter Symbiose : Ouverture de Chantier
                  </h1>
                  <p>&nbsp;</p>
                </div>
                <div className="blog-columns2_right">
                  <figure className="pic">
                    <img
                      src={require('../assets/images/blog/blog9_big.png')}
                    />
                  </figure>
                </div>
              </div>
              <div className="blog-columns2 blog9">
                <div className="blog-columns2_left">
                  <div className="main">
                    Démarrage du projet Symbiose : Le chantier est lancé !
                  </div>
                  <p className="highlighted">
                    Introduction :
                  </p>
                  <p>
                    Nous sommes ravis de vous annoncer le lancement officiel des travaux du projet 
                    Symbiose, un nouveau quartier qui prendra forme au cœur du Mont-sur-
                    Lausanne. Ce projet ambitieux s’inscrit dans une démarche de développement 
                    harmonieux et durable, visant à offrir à ses futurs habitants un cadre de vie 
                    exceptionnel.
                  </p>
                  <p className="highlighted">
                    Avancement des travaux :
                  </p>
                  <p>
                    Le chantier a officiellement débuté avec l’installation de l’infrastructure 
                    nécessaire. Les équipes sur place ont déjà commencé les premiers travaux de 
                    terrassement, une étape essentielle pour préparer le terrain aux futures 
                    fondations. L’espace est désormais sécurisé, et les premiers engins sont à 
                    l’œuvre pour modeler le terrain.
                  </p>
                  <p className="highlighted">
                    Prochaines étapes :
                  </p>
                  <p>
                    Les travaux vont se poursuivre avec la réalisation du radier, qui constituera la 
                    base solide des bâtiments. Nous vous informerons lors des prochaines avancées 
                    significatives.
                  </p>
                  <p className="highlighted">
                    Visuels :
                  </p>
                  <p>
                    Découvrez en images les premiers moments du chantier, avec des photos prises 
                    sur place pour vous permettre de suivre la transformation du site en temps réel.
                  </p>
                  <p className="highlighted">
                    Conclusion :
                  </p>
                  <p>
                    Le projet Symbiose avance selon le planning prévu. Nous vous remercions de 
                    l’intérêt que vous portez à ce chantier et restons à votre disposition pour toute 
                    question ou information complémentaire.
                  </p>
                </div>
              </div>
            </article>
          </div>
        </main>
        <section className="section-subscribe">
          <div className="container">
            <article className="section-subscribe_left">
              <p className="title">NEWSLETTER</p>
              Recevez des conseils et informations sur l'actualité de
              l'immobilier avec notre newsletter.
            </article>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="section-subscribe_form">
              <Input
                conClassName={'input'}
                errors={errors}
                name={'email'}
                label={'Email*'}
                ref={register(validation.email)}
              />
              <button
                disabled={isLoading}
                type="submit"
                className="btn btn-gradient">
                Je m’inscris
              </button>
            </form>
          </div>
        </section>

        <section className="section-conseil">
            <div className="container">
              <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
            </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default BlogArticle9;
