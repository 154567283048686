import React, {useState} from 'react';
import IncludeScript from '../components/IncludeScript';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {Link} from 'react-router-dom';
import ContactModal from '../components/ContactModal';


interface IMainPageProps {

}

const MainPage = ({}: IMainPageProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="page-index">
    <div className="page">
      <IncludeScript name={'initCarousel'}/>
      <Header />

      <main className="index-main">
        <div className="index-main_welcome">
          <h1>Votre expert immobilier</h1>
        </div>
      </main>

      <section className="section-news">
        <div className="container">
          <div className="row">
            <div className="col-12">
                <p>Depuis 2007, NewHome Services s'impose comme un acteur clé dans le secteur
                   immobilier de la Suisse romande, en mettant à disposition son <span className="bold">expertise</span> dans le développement et la réalisation de <span className="bold">projets immobiliers</span> variés. Notre équipe, forte d'une expérience significative, possède une connaissance approfondie du marché et sait anticiper les  <span className="bold">tendances</span> pour mieux servir nos clients.</p>
                <p>Nous nous engageons à offrir des <span className="bold">conseils objectifs</span> et des <span className="bold">solutions personnalisées</span>, visant à optimiser vos décisions immobilières. Nos réalisations, qui incluent des immeubles résidentiels locatifs, des propriétés par étages (PPE), ainsi que des villas et la rénovation de bâtiments historiques, témoignent de notre capacité à <span className="bold">répondre aux exigences spécifiques</span> de nos clients.</p>
                <p>Chez NewHome Services, notre priorité est de <span className="bold">valoriser</span> votre propriété grâce à des stratégies éprouvées et une planification rigoureuse, en vous accompagnant à chaque étape de votre projet.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-announce">
          <div className="container">
            <div className="announce-block">
                <article className="announce-text">
                <div className="text">
                        <p className="title">Symbiose : début des travaux au Mont-sur-Lausanne</p>
                        Nous avons le plaisir de vous annoncer que le chantier du projet Symbiose a officiellement débuté au Mont-sur-Lausanne. Les travaux de terrassement sont actuellement en cours, marquant ainsi une première étape clé dans la réalisation de ce nouveau quartier. La livraison du projet est prévue pour le printemps 2026.
                        Il reste encore quelques lots disponibles à la vente. N'hésitez pas à nous contacter pour plus d'informations sur cette opportunité unique.
                </div>
                </article>
                <figure className="announce-pic"><img src={require('../assets/images/pic-news.png')} /></figure>
            </div>
          </div>
      </section>

      <section className="section-carousel">
        <div className="section-carousel_left">
          <div className="owl-carousel owl-theme">
            <div className="item">
              <figure className="carousel-pic"><img src={require('../assets/images/index/pic1.jpg')} /></figure>
            </div>
            <div className="item">
              <figure className="carousel-pic"><img src={require('../assets/images/index/pic2.jpg')} /></figure>
            </div>
            <div className="item">
              <figure className="carousel-pic"><img src={require('../assets/images/index/pic3.jpg')} /></figure>
            </div>
            <div className="item">
              <figure className="carousel-pic"><img src={require('../assets/images/index/pic4.jpg')} /></figure>
            </div>
            <div className="item">
              <figure className="carousel-pic"><img src={require('../assets/images/index/pic5.jpg')} /></figure>
            </div>
            <div className="item">
              <figure className="carousel-pic"><img src={require('../assets/images/index/pic6.jpg')} /></figure>
            </div>
            <div className="item">
              <figure className="carousel-pic"><img src={require('../assets/images/index/pic7.jpg')} /></figure>
            </div>
            <div className="item">
              <figure className="carousel-pic"><img src={require('../assets/images/index/pic8.jpg')} /></figure>
            </div>
            <div className="item">
              <figure className="carousel-pic"><img src={require('../assets/images/index/pic9.jpg')} /></figure>
            </div>
            <div className="item">
              <figure className="carousel-pic"><img src={require('../assets/images/index/pic10.jpg')} /></figure>
            </div>
            <div className="item">
              <figure className="carousel-pic"><img src={require('../assets/images/index/pic11.jpg')} /></figure>
            </div>
            <div className="item">
              <figure className="carousel-pic"><img src={require('../assets/images/index/pic12.jpg')} /></figure>
            </div>
            <div className="item">
              <figure className="carousel-pic"><img src={require('../assets/images/index/pic13.jpg')} /></figure>
            </div>
          </div>
        </div>
        <div className="section-carousel_right">
          <div className="section-carousel_more">
            <p className="number">200 +</p>
            <Link to={'/portfolio'}>Voir tous les projets</Link>
          </div>
        </div>
      </section>

      {isOpen && <ContactModal isOpen={isOpen} setOpen={setIsOpen}/>}
      <section className="section-conseil">
        <div className="container">
          <a onClick={() => {
            setIsOpen(true);
            (window as any).dataLayer.push({event: 'contactClick'});
            }
          } className="btn btn-gradient"><span>Contact</span></a>
          <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
        </div>
      </section>

      <Footer/>
    </div>
    </div>
  )
};

export default MainPage;
